import React from "react"
import { Link } from "gatsby"

import { GatsbyImage } from "../"

import styles from "./index.module.css"

export default ({ cinema, ellipsis = false }) => {
  return (
    <div className={styles.gridItem} key={cinema.url}>
      <Link className={styles.link} to={`/cinema/${cinema.url}`}>
        <div>
          <GatsbyImage
            imageWrapper={cinema.images ? cinema.images[0] : {}}
            style={styles.thumbnail}
          />
          <div className={ellipsis ? styles.titleEllipsis : styles.title}>
            {cinema.title}
          </div>
          <div
            className={ellipsis ? styles.dateFieldEllipsis : styles.dateField}
          >
            {cinema.city &&
              cinema.country &&
              [cinema.city, cinema.country].join(", ")}
          </div>
        </div>
      </Link>
    </div>
  )
}
