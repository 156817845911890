import React from "react";
import { Link } from "gatsby";

import styles from "./index.module.css";

export default ({ values }) => (
  <div className={styles.main}>
    <span className={styles.label}>Architectural style: </span>
    <span>
      {values.map((element, index) => (
        <span key={element}>
          <Link
            className={styles.link}
            to={`/cinesearch?architecturalStyle=${element}`}
          >
            {element}
          </Link>
          {index < values.length - 1 && ", "}
        </span>
      ))}
    </span>
  </div>
);
