import React from "react";

import noImageFoundJpg from "../../../assets/images/no-image-found.jpg";

const noImageFound = className => (
  <img
    id="heroImage"
    alt="not found"
    src={noImageFoundJpg}
    className={className}
  />
);
export default ({ imageWrapper, queryParams, style }) => {
  return (
    <>
      {imageWrapper.image && imageWrapper.image.file && (
        <img
          id="heroImage"
          alt={imageWrapper.description || imageWrapper.title}
          src={
            imageWrapper.image.file.url +
            `${queryParams ? "?" + queryParams : ""}`
          }
          className={style}
        />
      )}
      {!imageWrapper.image && noImageFound(style)}
      {imageWrapper.image && !imageWrapper.image.file && noImageFound(style)}
    </>
  );
};
