import React from "react";
import { StaticQuery, graphql } from "gatsby";

// See https://github.com/FortAwesome/react-fontawesome/issues/134
import "@fortawesome/fontawesome-svg-core/styles.css";

import { Footer, SiteHeader } from "..";
import styles from "./index.module.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={styles.page}>
        <SiteHeader />
        <div className={styles.mainContent}>{children}</div>
        <Footer />
      </div>
    )}
  />
);
