import React from "react";

import styles from "./index.module.css";

export default ({ label, icon, value }) => (
  <div className={styles.infoField}>
    <span className={styles.label}>
      {!!icon && <span className={styles.icon}> {icon}</span>}
      {label}
      {": "}
    </span>
    <span className={styles.fieldValue}>{value}</span>
  </div>
);
