import React from "react"
import { Link } from "gatsby"

import styles from "./index.module.css"

export default () => (
  <div className={styles.footer}>
    <Link className={styles.link} to={`/contact/`}>
      CONTACT
    </Link>{" "}
    <Link className={styles.link} to={`/terms/`}>
      TERMS & CONDITIONS
    </Link>{" "}
    <Link className={styles.link} to={`/privacy/`}>
      PRIVACY POLICY
    </Link>{" "}
    <Link className={styles.link} to={`/impressum/`}>
      IMPRESSUM
    </Link>{" "}
    <Link className={styles.link} to={`/faq/`}>
      F.A.Q.
    </Link>
  </div>
)
