import React from "react"

import { Link } from "gatsby"

import { GatsbyImage } from "../"

import styles from "./index.module.css"

const SearchResult = ({ cinema }) => (
  <li key={cinema.url} className={styles.searchResult}>
    <Link
      key={cinema.url}
      className={styles.searchResultLink}
      to={`/cinema/${cinema.url}`}
    >
      <div className={styles.thumbnailContrainer}>
        <GatsbyImage
          imageWrapper={cinema.images ? cinema.images[0] : {}}
          style={styles.searchResulThumbnail}
        />
      </div>

      <div className={styles.searchResultInfo}>
        <div className={styles.searchResultTitle}>{cinema.title}</div>

        <div className={styles.searchResultInfoLine}>
          {cinema.status} {cinema.city && `| ${cinema.city} `}
          {cinema.country && `| ${cinema.country} `}
          {cinema.yearOfConstruction && `| ${cinema.yearOfConstruction} `}
        </div>

        <div className={styles.teaserText}>
          {cinema.description
            ? cinema.description.description.substring(0, 190) + "... "
            : ""}
        </div>
      </div>
    </Link>
  </li>
)

export default SearchResult
