import React from "react"

import { isNil } from "ramda"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faGlobe, faHome } from "@fortawesome/free-solid-svg-icons"

import {
  ArchitecturalStyleInfoField,
  PillsContainer,
  TextInfoField,
  TextInfoWithLinkField
} from "../../components"

import { getFullAddress } from "../../utils/cinema"

import styles from "./index.module.css"

export default ({ cinema }) => {
  const fullAddress = getFullAddress(cinema)

  return (
    <div className={styles.dataContainer}>
      <div className={styles.addressSection}>
        {fullAddress && cinema.address && (
          <TextInfoWithLinkField
            label="Address"
            value={fullAddress}
            link={`/cinemap?zoom=8&lat=${cinema.address.lat}&lon=${cinema.address.lon}`}
            icon={<FontAwesomeIcon icon={faHome} />}
          />
        )}
        {fullAddress && !cinema.address && (
          <TextInfoField
            label="Address"
            value={fullAddress}
            icon={<FontAwesomeIcon icon={faHome} />}
          />
        )}
        {cinema.homepage && (
          <TextInfoWithLinkField
            label="Homepage"
            value={cinema.homepage}
            link={cinema.homepage}
            external={true}
            icon={<FontAwesomeIcon icon={faGlobe} />}
          />
        )}
        {cinema.email && (
          <TextInfoWithLinkField
            label="Email"
            value={cinema.email}
            link={`mailto:${cinema.email}`}
            icon={<FontAwesomeIcon icon={faEnvelope} />}
          />
        )}

        {(cinema.email || cinema.homepage || fullAddress) && <br />}

        {!isNil(cinema.yearOfConstruction) && (
          <TextInfoField
            label="Year of construction (completed)"
            value={cinema.yearOfConstruction}
          />
        )}
        {!isNil(cinema.yearOfFirstOpening) && (
          <TextInfoField
            label="Year of first opening"
            value={cinema.yearOfFirstOpening}
          />
        )}
        {cinema.yearOfReopening && (
          <TextInfoField
            label="Year of reopening"
            value={cinema.yearOfReopening}
          />
        )}
        {cinema.architecturalStyle && (
          <ArchitecturalStyleInfoField
            values={cinema.architecturalStyle.map((style) => style.name)}
          />
        )}
        {cinema.numOfScreens > 0 && (
          <TextInfoField
            label="Number of screens"
            value={cinema.numOfScreens}
          />
        )}
        {cinema.numOfSeats && (
          <TextInfoField label="Number of seats" value={cinema.numOfSeats} />
        )}
      </div>

      {cinema.featureList && (
        <PillsContainer
          elements={cinema.featureList.map((f) => f.description)}
        />
      )}
    </div>
  )
}
