import React from "react";

import styles from "./index.module.css";

export default ({ createdAt, updatedAt }) => (
  <div className={styles.topText}>
    Published: <span className={styles.fieldValue}>{createdAt}</span>
    {updatedAt && " | Updated: "}
    {updatedAt && <span className={styles.fieldValue}>{updatedAt}</span>}
  </div>
);
