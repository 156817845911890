export const getFullAddress = (cinema) => {
  const address = [];
  cinema.street && address.push(cinema.street);
  cinema.city && address.push(cinema.city);
  cinema.state && cinema.state !== cinema.city && address.push(cinema.state); //sometimes these can be the same
  cinema.zipCode && address.push(cinema.zipCode);
  cinema.country && address.push(cinema.country);

  return address.join(", ");
};
