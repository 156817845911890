import React from "react"
import Img from "gatsby-image"

import noImageFoundJpg from "../../../assets/images/no-image-found.jpg"

const noImageFound = (className) => (
  <img
    id="heroImage"
    alt="not found"
    src={noImageFoundJpg}
    className={className}
  />
)
export default ({ imageWrapper, style }) => {
  return (
    <>
      {imageWrapper.image && imageWrapper.image.fluid && (
        <Img className={style} fluid={imageWrapper.image.fluid} />
      )}
      {!imageWrapper.image && noImageFound(style)}
      {imageWrapper.image && !imageWrapper.image.fluid && noImageFound(style)}
    </>
  )
}
