import React from "react"

import styles from "./index.module.css"

export default ({ href, label, external = false }) => (
  <a
    className={styles.link}
    href={href}
    target={external ? "_blank" : ""}
    rel="noreferrer"
  >
    {label}
  </a>
)
