import React from "react";

import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import styles from "./index.module.css";

const NavBar = ({ visible }) => {
  return (
    <>
      <nav>
        <ul className={`${styles.navbar} ${visible && styles.showNavBar}`}>
          {/* <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/`}
              activeClassName={styles.activeLink}
            >
              home
            </Link>
          </li> */}
          <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/cinesearch`}
              activeClassName={styles.activeLink}
            >
              cinesearch
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/cinemap/`}
              activeClassName={styles.activeLink}
            >
              cinemap
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/cinestories/`}
              activeClassName={styles.activeLink}
            >
              cinestories
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/contribute/`}
              activeClassName={styles.activeLink}
            >
              contribute
            </Link>
          </li>
          <li className={styles.navListItem}>
            <Link
              className={styles.link}
              to={`/about/`}
              activeClassName={styles.activeLink}
            >
              about
            </Link>
          </li>
          <li className={`${styles.navListItem} ${styles.cineletterLink}`}>
            <a
              className={styles.link}
              href={"http://eepurl.com/hA9f3T"}
              target="_blank"
              rel="noreferrer"
            >
              cineletter
              <span style={{paddingRight: 5}}/>
              <span style={{height: 30,verticalAlign: 'middle'}}>
                <FontAwesomeIcon icon={faEnvelope}/>
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
