import React from "react";

import { StyledLink } from "..";

import styles from "./index.module.css";

export default ({
  label,
  value,
  link,
  icon,
  external = false,
  newLine = true
}) => (
  <div className={styles.infoField}>
    {newLine && (
      <div className={styles.label}>
        {!!icon && <span className={styles.icon}> {icon}</span>}
        <span>{label}: </span>
      </div>
    )}
    {!newLine && <span className={styles.label}>{label}: </span>}

    <span className={styles.fieldValue}>
      <StyledLink href={link} external={external} label={value} />
    </span>
  </div>
);
